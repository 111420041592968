import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ImageStyleTypes } from '../../lib/constants'

export interface FormState {
  setting: string
  from: string
  toEnd: string[]
  subject: string
  signature_name: string
  signature_email: string
  images_style: ImageStyleTypes
}

const initialState: FormState = {
  setting: '',
  from: '',
  toEnd: [],
  subject: '',
  signature_name: '',
  signature_email: '',
  images_style: ImageStyleTypes.auto,
}

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updateField(
      state,
      action: PayloadAction<{ field: keyof Omit<FormState, 'toEnd' | 'images_style'>; value: string }>,
    ) {
      const { field, value } = action.payload
      state[field] = value
    },
    clearFormData(state) {
      return initialState
    },
    updateRecipient(state, action: PayloadAction<string[]>) {
      state.toEnd = action.payload
    },
    updateImageStyle(state, action: PayloadAction<ImageStyleTypes>) {
      state.images_style = action.payload
    },
    updateFormData(state, action: PayloadAction<FormState>) {
      state.setting = action.payload.setting
      state.subject = action.payload.subject
      state.from = action.payload.from
      state.signature_name = action.payload.signature_name
      state.signature_email = action.payload.signature_email
      state.toEnd = action.payload.toEnd
    },
  },
})

export const { updateField, clearFormData, updateImageStyle, updateRecipient, updateFormData } =
  formSlice.actions
export default formSlice.reducer
