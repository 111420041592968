import homeIcon from '../assets/images/home-icon.svg'
import createIcon from '../assets/images/create-icon.svg'
import galleryIcon from '../assets/images/gallery-icon.svg'
import pricingIcon from '../assets/images/top-up-icon.svg'
import autoIcon from '../assets/images/auto-icon.svg'
import generalIcon from '../assets/images/general-icon.svg'
import realisticIcon from '../assets/images/realistic-icon.svg'
import designIcon from '../assets/images/design-icon.svg'
import render3DIcon from '../assets/images/render3D-icon.svg'
import animeIcon from '../assets/images/anime-icon.svg'
import pixelIcon from '../assets/images/pixel-icon.svg'
import { FormState } from '../store/slice/formDataSlice'

export const SERVICE_ID = process.env.REACT_APP_SERVICE_ID || ''
export const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID || ''
export const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY || ''

export enum AppRoutes {
  home = '/',
  create = '/create',
  gallery = '/gallery',
  pricing = '/pricing',
  googleCallback = '/auth/google/callback',
  appleCallback = '/auth/apple/callback',
  terms = '/terms',
  privacy = '/privacy',
}

export enum PriceType {
  price_20 = 'price_20',
  price_50 = 'price_50',
  price_100 = 'price_100',
}

export enum ImageStyleTypes {
  auto = 'AUTO',
  general = 'GENERAL',
  realistic = 'REALISTIC',
  design = 'DESIGN',
  render3D = 'RENDER_3D',
  anime = 'ANIME',
  pixelArt = 'PIXEL_ART',
}

export const imageStyles = [
  { icon: autoIcon, text: 'Random', type: ImageStyleTypes.auto },
  { icon: generalIcon, text: 'Universal', type: ImageStyleTypes.general },
  { icon: realisticIcon, text: 'Realistic', type: ImageStyleTypes.realistic },
  { icon: animeIcon, text: 'Anime', type: ImageStyleTypes.anime },
  { icon: designIcon, text: 'Graphics', type: ImageStyleTypes.design },
  { icon: render3DIcon, text: '3D Render', type: ImageStyleTypes.render3D },
  { icon: pixelIcon, text: 'Pixel Art', type: ImageStyleTypes.pixelArt },
]

export const tabs = [
  { name: 'Home', link: AppRoutes.home, icon: homeIcon },
  { name: 'Create', link: AppRoutes.create, icon: createIcon },
  { name: 'Gallery', link: AppRoutes.gallery, icon: galleryIcon },
  { name: 'Top Up', link: AppRoutes.pricing, icon: pricingIcon },
]

export const tooltipShareText =
  'By default your email is private. Enabling this option will share generated email to community and will save you 1 credit.'

export const tooltipFontsText =
  'Some Email clients (like Gmail) don’t support linking external fonts, enable this option if you know what you’re doing.'

export const tagOptions = {
  settingTags: ['Thanksgiving', 'Christmas', 'Mario', 'Rick and Morty', 'Harry Potter', 'Mad Max'],
  fromTags: ['Pilgrim', 'Santa', 'Super Mario', 'Rick Sanchez', 'Dumbledore', 'Mad Max '],
}

export const requiredFields: Array<keyof FormState> = [
  'setting',
  'signature_name',
  'subject',
]
