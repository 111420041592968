import React from 'react'
import { Sidebar } from '../../components/sidebar/Sidebar'
import { Placeholder } from '../../components/placeholder/Placeholder'
import './style.scss'
import { Popup } from '../../components/popup/Popup'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Loader } from '../../components/loader/Loader'
import EmailComponent from '../../components/HTMLparser/HTMLcomponent'
import { Toolbar } from '../../components/toolbar/Toolbar'

export const CreatePage: React.FC = () => {
  const { emailContent, loading } = useSelector((state: RootState) => state.testEmail)
  const error = useSelector((state: RootState) => state.testEmail.error)

  const renderContent = () => {
    if (loading) {
      return <Loader showProgress={true} />
    }
    if (error) {
      return <Placeholder />
    }
    if (emailContent && emailContent.html) {
      return (
        <>
          <Toolbar />
          <EmailComponent html={emailContent.html} />
          <div className='popup-layout-box'>
            <Popup />
          </div>
        </>
      )
    }
    return <Placeholder />
  }

  return (
    <div className='create-page'>
      <Sidebar />
      <div className={loading ? 'load-content' : 'content-wrapper'}>{renderContent()}</div>
    </div>
  )
}
