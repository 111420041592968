import { FormState } from '../../store/slice/formDataSlice'

export function getAccessToken() {
  return localStorage.getItem('accessToken');
}

export function setAccessToken(accessToken: string) {
  localStorage.setItem('accessToken', accessToken);
}

export function removeAccessToken() {
  localStorage.removeItem('accessToken');
}

export function getFormData(): FormState | null {
  const formData = localStorage.getItem('formData');
  if (formData) {
    return JSON.parse(formData);
  }
  return null
}

export function setFormData(formData: FormState) {
  localStorage.setItem('formData', JSON.stringify(formData));
}

export function removeFormData() {
  localStorage.removeItem('formData');
}

export function getIsFirstLogin() {
  const isFirstLogin = localStorage.getItem('isFirstLogin')
  if (isFirstLogin) {
    return JSON.parse(isFirstLogin)
  }
  return isFirstLogin;
}

export function setIsFirstLogin(isFirstLogin: boolean) {
  localStorage.setItem('isFirstLogin', JSON.stringify(isFirstLogin));
}

export function removeIsFirstLogin() {
  localStorage.removeItem('isFirstLogin');
}

export function getSuccessSendCount() {
  const isFirstLogin = localStorage.getItem('successSendCount')
  if (isFirstLogin) {
    return JSON.parse(isFirstLogin)
  }
  return isFirstLogin;
}

export function setSuccessSendCount(successSendCount: number) {
  localStorage.setItem('successSendCount', JSON.stringify(successSendCount));
}

export function removeSuccessSendCount() {
  localStorage.removeItem('successSendCount');
}
