import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../../button/Button'
import { Text } from '../../blocks/text/Text'
import error from '../../../assets/images/Danger Triangle.svg'
import creditsIcon from '../../../assets/images/credits-icon.svg'
import './style.scss'
import { Flex } from '../../blocks/flex/Flex'
import { updateField, updateImageStyle } from '../../../store/slice/formDataSlice'
import { RootState, useAppDispatch } from '../../../store'
import { generateEmailContent } from '../../../store/thunk/testEmailGenerate'
import { ToggleSwitch } from '../../switchButton/SwitchButton'
import { BtnStars, Info } from '../../blocks/icon/Icon'
import { fetchUser } from '../../../store/slice/authSlice'
import { useAuth } from '../../../lib/hooks/useAuth'
import { setAuthModal } from '../../../store/slice/modalSlice'
import ToolTip from '../../toolTip/ToolTip'
import {
  imageStyles,
  ImageStyleTypes,
  requiredFields,
  tagOptions,
  tooltipShareText,
  tooltipFontsText,
} from '../../../lib/constants'
import clsx from 'clsx'

interface FormState {
  setting: string
  from: string
  subject: string
  signature_name: string
  signature_email: string
}

export const FormComponent: FC = () => {
  const dispatch = useAppDispatch()
  const { isSignedIn, user } = useAuth()
  const { loading, emailContent } = useSelector((state: RootState) => state.testEmail)
  const formData = useSelector((state: RootState) => state.formData)
  const [selectedTags, setSelectedTags] = useState<{ setting: string; from: string }>({
    setting: formData.setting,
    from: formData.from,
  })
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isFormValid, setIsFormValid] = useState(true)
  const [isEnableShare, setIsEnableShare] = useState(true)
  const [isEnableFonts, setIsEnableFonts] = useState(false)

  useEffect(() => {
    const isValid = requiredFields.every((field) => formData[field])
    setIsFormValid(isValid && !errorMessage)
  }, [formData, errorMessage])

  const handleSubmit = async () => {
    if (!isFormValid || loading) {
      return
    }

    if (!isSignedIn) {
      dispatch(setAuthModal(true))
      return
    }

    const errors = requiredFields.filter((field) => !formData[field])
    if (errors.length > 0) {
      setErrorMessage('Please fill in all the fields marked with *')
      return
    } else {
      await dispatch(
        generateEmailContent({
          style: formData.setting,
          from_name: formData.from || formData.signature_name,
          email_prompt: formData.subject,
          signature_name: formData.signature_name,
          signature_email: formData.signature_email || (user?.email ?? ''),
          is_public: isEnableShare,
          images_style: formData.images_style,
          use_unsafe_fonts: isEnableFonts,
        }),
      )
      await dispatch(fetchUser())
      setErrorMessage(null)
    }
  }

  const handleImageStyleChange = (imageStyle: ImageStyleTypes) => () => {
    dispatch(updateImageStyle(imageStyle))
  }

  const handleInputChange = (field: keyof FormState, value: string) => {
    dispatch(updateField({ field, value }))

    if (field === 'setting' && selectedTags.setting !== value) {
      setSelectedTags((prev) => ({
        ...prev,
        setting: '',
      }))
    } else if (field === 'from' && selectedTags.from !== value) {
      setSelectedTags((prev) => ({
        ...prev,
        from: '',
      }))
    }
  }

  const handleTagClick = (field: keyof FormState, value: string) => {
    dispatch(updateField({ field, value }))
    setSelectedTags((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const toggleShare = () => {
    setIsEnableShare((prevState) => !prevState)
  }

  const toggleFonts = () => {
    setIsEnableFonts((prevState) => !prevState)
  }

  useEffect(() => {
    handleInputChange('signature_name', user?.name ?? '')
  }, [user?.name])

  const buttonRight = () => {
    return (
      <div className='button-right'>
        <img className='button-right-icon' src={creditsIcon} alt='' role='presentation' />
        <span className='button-right-text'>{isEnableShare ? 2 : 3}</span>
      </div>
    )
  }

  return (
    <>
      <div className='form-container'>
        <form>
          <Flex justify='space-between'>
            <Flex align='center' gap={5}>
              <Text
                text='Share Email to community'
                fontSize='16px'
                color='var(--dino-white)'
                fontWeight='500'
                styles={{ paddingRight: 0 }}
              />
              <ToolTip text={tooltipShareText}>
                <Info />
              </ToolTip>
            </Flex>
            <Flex align='center' justify='flex-end' gap={10}>
              <div className='switch-info'>
                <span className='switch-info-text'>1</span>
                <img className='switch-info-icon' src={creditsIcon} alt='' role='presentation' />
                <span className='switch-info-text'>less</span>
              </div>
              <ToggleSwitch isToggled={isEnableShare} onToggle={toggleShare} />
            </Flex>
          </Flex>
          <div className='line' />
          <Flex justify='space-between'>
            <Flex align='center' gap={5}>
              <Text
                text='Allow custom fonts'
                fontSize='16px'
                color='var(--dino-white)'
                fontWeight='500'
                styles={{ paddingRight: 0 }}
              />
              <ToolTip text={tooltipFontsText}>
                <Info />
              </ToolTip>
            </Flex>
            <ToggleSwitch isToggled={isEnableFonts} onToggle={toggleFonts} />
          </Flex>
          <div className='line' />
          <Flex direction='column' gap={15}>
            <label htmlFor='subject'>
              Email prompt<p>*</p>
            </label>
            <Text color='var(--dino-white)' opacity={0.7} fontSize='14px'>
              What is it you want to tell your friends about? <br />
              (If you want to address somebody by name, mention specific place or date, etc., —
              write it all here)
            </Text>
            <textarea
              placeholder='Remind Jeff we’re having a Christmas party at Debora’s place on December 24th, from 6pm to Midnight...'
              id='subject'
              rows={4}
              value={formData.subject}
              onChange={(e) => dispatch(updateField({ field: 'subject', value: e.target.value }))}
            />
          </Flex>
          <div className='line' />
          <Flex direction='column' gap={15}>
            <label>Email style</label>
            <Text
              color='var(--dino-white)'
              opacity={0.7}
              fontSize='14px'
              text='Choose a custom style for your email'
            />
            <div className='image-styles'>
              {imageStyles.map(({ type, text, icon }) => {
                return (
                  <div
                    key={type}
                    className={clsx('image-style', formData.images_style === type && 'active')}
                    onClick={handleImageStyleChange(type)}
                  >
                    <img src={icon} alt='' />
                    <span className='image-style-text'>{text}</span>
                  </div>
                )
              })}
            </div>
          </Flex>
          <div className='line' />
          <Flex direction='column' gap={15}>
            <label htmlFor='setting'>
              Email theme<p>*</p>
            </label>
            <Text
              color='var(--dino-white)'
              opacity={0.7}
              fontSize='14px'
              text='Choose a TV show, movie, book or popular subject, etc.'
            />
            <input
              type='text'
              placeholder='Setting'
              id='setting'
              value={formData.setting}
              onChange={(e) => handleInputChange('setting', e.target.value)}
            />
            <Text color='var(--dino-white)' opacity={0.7} fontSize='14px' text='Trending themes:' />
            <div className='tag-container'>
              {tagOptions.settingTags.map((tag, index) => (
                <button
                  key={index}
                  type='button'
                  className={`tag-button ${selectedTags.setting === tag ? 'selected-tag' : ''}`}
                  onClick={() => handleTagClick('setting', tag)}
                >
                  {tag}
                </button>
              ))}
            </div>
          </Flex>
          <div className='line' />
          <Flex direction='column' gap={15}>
            <label htmlFor='from'>Character</label>
            <Text
              text='Whose character (fictional or non-fictional) do you want this email to be sent by? This will affect the writing style and will also be the Sender’s name in recipient’s inbox.'
              color='var(--dino-white)'
              opacity={0.7}
              fontSize='14px'
            />
            <input
              type='text'
              placeholder='Character’s name'
              id='from'
              value={formData.from}
              onChange={(e) => handleInputChange('from', e.target.value)}
            />
            <Text
              text='Trending characters:'
              color='var(--dino-white)'
              opacity={0.7}
              fontSize='14px'
            />
            <div className='tag-container'>
              {tagOptions.fromTags.map((tag, index) => (
                <button
                  key={index}
                  type='button'
                  className={`tag-button ${selectedTags.from === tag ? 'selected-tag' : ''}`}
                  onClick={() => handleTagClick('from', tag)}
                >
                  {tag}
                </button>
              ))}
            </div>
          </Flex>
          <div className='line' />
          <Flex direction='column' gap={15}>
            <label htmlFor='signature_name'>
              Your signature<p>*</p>
            </label>
            <Text
              text='Let recipient know who sent the email.'
              color='var(--dino-white)'
              opacity={0.7}
              fontSize='14px'
            />
            <input
              type='text'
              className='first-i'
              placeholder='Your name'
              id='signature_name'
              value={formData.signature_name}
              onChange={(e) => handleInputChange('signature_name', e.target.value)}
            />
            {/*<div className='twin-input-container'>*/}
            {/*  <input*/}
            {/*    type='text'*/}
            {/*    className='first-i'*/}
            {/*    placeholder='Your name'*/}
            {/*    id='signature_name'*/}
            {/*    value={formData.signature_name}*/}
            {/*    onChange={(e) =>*/}
            {/*      dispatch(updateField({ field: 'signature_name', value: e.target.value }))*/}
            {/*    }*/}
            {/*  />*/}
            {/*  <input*/}
            {/*    type='email'*/}
            {/*    className='second-i'*/}
            {/*    placeholder='youremail.gmail.com'*/}
            {/*    id='signature_email'*/}
            {/*    value={formData.signature_email}*/}
            {/*    onChange={(e) =>*/}
            {/*      dispatch(updateField({ field: 'signature_email', value: e.target.value }))*/}
            {/*    }*/}
            {/*  />*/}
            {/*</div>*/}
          </Flex>
          {errorMessage && (
            <Flex>
              <img src={error} alt='' />
              <span className='error-span'>{errorMessage}</span>
            </Flex>
          )}
        </form>
      </div>
      <div className='btn-container'>
        <Button
          text={loading ? 'Generating' : `Generate ${emailContent ? 'new' : 'an'} email`}
          type='submit'
          width='100%'
          LeftSvgIcon={BtnStars}
          rightElement={buttonRight}
          className='submit-form-button'
          onClick={handleSubmit}
        />
      </div>
    </>
  )
}
