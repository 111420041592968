import { configureStore } from '@reduxjs/toolkit'
import formReducer from './slice/formDataSlice'
import emailReducer from './slice/emailSlice'
import layoutReducer from './slice/layoutSlice'
import testEmailReducer from './slice/testEmailSlice'
import authReducer from './slice/authSlice'
import galleryReducer from './slice/gallerySlice'
import modalReducer from './slice/modalSlice'
import { useDispatch } from 'react-redux'

export const store = configureStore({
  reducer: {
    formData: formReducer,
    email: emailReducer,
    layout: layoutReducer,
    testEmail: testEmailReducer,
    auth: authReducer,
    gallery: galleryReducer,
    modal: modalReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
