import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RecipientsModal } from '../../components/recipientsModal/RecipientsModal'

interface ModalState {
  isOutOfCreditsModal: boolean
  isOopsModal: boolean
  isSuccessModal: boolean
  isSuccessFeedbackModal: boolean
  isCheckoutSuccessModal: boolean
  isAuthModal: boolean
  isRecipientsModal: boolean
  isDeleteAccountModal: boolean
}

const initialState: ModalState = {
  isOutOfCreditsModal: false,
  isOopsModal: false,
  isSuccessModal: false,
  isSuccessFeedbackModal: false,
  isCheckoutSuccessModal: false,
  isAuthModal: false,
  isRecipientsModal: false,
  isDeleteAccountModal: false,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setAuthModal: (state, action) => {
      state.isAuthModal = action.payload
    },
    setOutOfCreditsModal: (state, action) => {
      state.isOutOfCreditsModal = action.payload
    },
    setOopsModal: (state, action) => {
      state.isOopsModal = action.payload
    },
    setSuccessModal: (state, action) => {
      state.isSuccessModal = action.payload
    },
    setSuccessFeedbackModal: (state, action) => {
      state.isSuccessFeedbackModal = action.payload
    },
    setRecipientsModal: (state, action) => {
      state.isRecipientsModal = action.payload
    },
    setDeleteAccountModal: (state, action) => {
      state.isDeleteAccountModal = action.payload
    },
    setCheckoutSuccessModal: (state, action) => {
      state.isCheckoutSuccessModal = action.payload
    },
  },
})

export const {
  setAuthModal,
  setOutOfCreditsModal,
  setOopsModal,
  setSuccessModal,
  setSuccessFeedbackModal,
  setRecipientsModal,
  setDeleteAccountModal,
  setCheckoutSuccessModal,
} = modalSlice.actions
export default modalSlice.reducer
