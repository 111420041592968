import { Text } from '../blocks/text/Text'
import loader from '../../assets/images/MM_Loader.gif'
import progress from '../../assets/images/prog.gif'
import './style.scss'
import { useEffect, useState } from 'react'

const messages = [
  'Brewing the perfect cup of digital coffee...',
  'Counting pixels one by one...',
  'Polishing email fonts...',
  'Convincing the servers to work harder...',
  'Detangling lines of code...',
  'Adding a sprinkle of marketing magic...',
  'Teaching your email to fly...',
  'Converting caffeine into creativity...',
  'Consulting with email wizards...',
  'Tuning the email frequency...',
  'Sharpening subject lines...',
  'Whispering sweet nothings to the data...',
  'Calibrating pixel perfection...',
  'Turning zeros into ones...',
  'Grooming HTML tags...',
  'Checking for email gremlins...',
  'Inserting witty puns...',
  'Running a background cat video...',
  'Sending a high-five to the cloud...',
  'Testing with invisible ink...',
]

export const Loader = ({showProgress = false}) => {
  const [currentMessage, setCurrentMessage] = useState(messages[0]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMessage(prevMessage => {
        const currentIndex = messages.indexOf(prevMessage);
        const nextIndex = (currentIndex + 1) % messages.length;
        return messages[nextIndex];
      });
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='loader-container'>
      <img src={loader} alt='loader' className='loader-image' />
      {showProgress && <img src={progress} alt='progress' className='loader-progress-image' />}
      <span color='var(--dino-white)' className='loader-text'>
        {currentMessage}
      </span>
    </div>
  )
}
