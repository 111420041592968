import CookieConsent from 'react-cookie-consent'
import cookieIcon from '../../assets/images/cookie.svg'
import './style.scss'
import { Button } from '../button/Button'

export const CookiesBanner = () => {
  return (
    <CookieConsent
      location='none'
      buttonText='gotcha 👌'
      cookieName='myAwesomeCookieName2'
      disableStyles={true}
      containerClasses='cookies-banner-container'
      contentClasses='cookies-banner-content'
      buttonClasses='cookies-banner-button'
      ButtonComponent={Button}
      customButtonProps={{
        text: 'gotcha 👌'
      }}
      expires={180}
    >
      <img src={cookieIcon} alt='' role='presentation' />
      <p>
        We use cookies to make your experience magical.
        <br />
        To learn more about our approach to data privacy, click here.
      </p>
    </CookieConsent>
  )
}
