import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './style.scss'
import logo from '../../assets/images/Logo.svg'
import userCircle from '../../assets/images/user-circle.svg'
import magicmail from '../../assets/images/magicmail.svg'
import menuIcon from '../../assets/images/more-vertical.svg'
import creditsIcon from '../../assets/images/credits-icon.svg'
import logoutIcon from '../../assets/images/log-out.svg'
import removeIcon from '../../assets/images/user-remove.svg'
import pricingIcon from '../../assets/images/pricing-icon.svg'
import { AppRoutes, tabs } from '../../lib/constants'
import clsx from 'clsx'
import { useAuth } from '../../lib/hooks/useAuth'
import { useAppDispatch } from '../../store'
import { setAuthModal, setDeleteAccountModal } from '../../store/slice/modalSlice'
import { getFormData, removeFormData } from '../../lib/services/asyncStorage'
import { updateFormData } from '../../store/slice/formDataSlice'

export const Navbar: FC = () => {
  const dispatch = useAppDispatch()
  const {
    isSignedIn,
    user,
    onGoogleCallback,
    onAppleCallback,
    onSignOut,
    onGetUser,
    onCheckSignIn,
  } = useAuth()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  const [showDropdown, setShowDropdown] = useState(false)

  const dropdownToggle = () => {
    setShowDropdown(!showDropdown)
  }

  const onClick = (route: AppRoutes) => () => navigate(route)

  const onSignOutClick = () => {
    dropdownToggle()
    onSignOut()
    navigate(AppRoutes.home)
  }

  const onCallback = async () => {
    if (pathname === AppRoutes.googleCallback) {
      const paramsString = search.replace('?', '')
      const searchParams = new URLSearchParams(paramsString)
      const code = searchParams.get('code')
      if (code) {
        await onGoogleCallback(code)
        const formData = getFormData()
        if (formData) {
          dispatch(updateFormData(formData))
        }
        navigate(AppRoutes.create)
        removeFormData()
      }
    }
    if (pathname === AppRoutes.appleCallback) {
      const paramsString = search.replace('?', '')
      const searchParams = new URLSearchParams(paramsString)
      const code = searchParams.get('code')
      const token = searchParams.get('id_token')
      if (code && token) {
        await onAppleCallback(code, token)
        const formData = getFormData()
        if (formData) {
          dispatch(updateFormData(formData))
        }
        navigate(AppRoutes.create)
        removeFormData()
      }
    }
  }

  const onDeleteAccountClick = () => {
    dropdownToggle()
    dispatch(setDeleteAccountModal(true))
  }

  const onAvatarClick = () => {
    isSignedIn ? dropdownToggle() : dispatch(setAuthModal(true))
  }

  useEffect(() => {
    if (pathname !== AppRoutes.googleCallback && pathname !== AppRoutes.appleCallback) {
      onCheckSignIn()
    }
    onCallback().catch()
  }, [])

  useEffect(() => {
    if (isSignedIn) {
      onGetUser()
    }
  }, [isSignedIn])

  return (
    <div className='navbar'>
      <div className='nav-box'>
        <div className='logo-box'>
          <img src={logo} alt='' className='logo' onClick={onClick(AppRoutes.home)} />
        </div>
        <nav className='tabs-box'>
          {tabs.map(({ name, icon, link }, index) => {
            const isPricing = link === AppRoutes.pricing
            const nameText = isPricing && !isSignedIn ? 'Pricing' : name
            const iconSrc = isPricing && !isSignedIn ? pricingIcon : icon
            return (
              <button
                key={index}
                className={clsx('tab', isPricing && isSignedIn && 'pricing', pathname === link && 'active')}
                onClick={onClick(link)}
              >
                <img src={iconSrc} alt='' />
                <span>{nameText}</span>
              </button>
            )
          })}
        </nav>
      </div>
      <div className='title-box'>
        <img src={magicmail} alt='' className='title' />
      </div>
      <div className='menu-box'>
        {isSignedIn && user && (
          <div className='credits'>
            <img src={creditsIcon} alt='' />
            <span>{user.credits || 0}</span>
          </div>
        )}
        {user?.picture ? (
          <img
            src={user.picture}
            alt=''
            className='avatar logged'
            referrerPolicy='no-referrer'
            onClick={onAvatarClick}
          />
        ) : (
          <img src={userCircle} alt='' className='avatar' onClick={onAvatarClick} />
        )}
        {isSignedIn && (
          <button
            className={clsx('more-button', showDropdown && 'opened')}
            onClick={dropdownToggle}
          >
            <img src={menuIcon} alt='' />
          </button>
        )}
        {showDropdown && (
          <div className='more-dropdown'>
            {user && (
              <div className='dropdown-item'>
                {user.name && <p className='user-name'>{user.name}</p>}
                {user.email && <p className='user-email'>{user.email}</p>}
              </div>
            )}
            <div className='dropdown-item'>
              <div className='logout' onClick={onSignOutClick}>
                <img className='dropdown-item-icon' src={logoutIcon} alt='' />
                <span>Log out</span>
              </div>
            </div>
            <div className='dropdown-item' onClick={onDeleteAccountClick}>
              <div className='remove'>
                <img className='dropdown-item-icon' src={removeIcon} alt='' />
                <span>Delete account</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
