import { ChangeEventHandler, FC, useState } from 'react'
import './style.scss'
import { Link } from 'react-router-dom'
import infoIcon from '../../assets/images/info-colored.svg'
import chatIcon from '../../assets/images/chat.svg'
import creditsIcon from '../../assets/images/credits-icon.svg'
import { Button } from '../../components/button/Button'
import { motion } from 'framer-motion'
import { useAuth } from '../../lib/hooks/useAuth'
import { useAppDispatch } from '../../store'
import { setAuthModal, setSuccessModal } from '../../store/slice/modalSlice'
import { stripeSession } from '../../lib/api/checkout'
import { PriceType } from '../../lib/constants'
import { DinomailLogo, WimbleLogo } from '../../components/blocks/icon/Icon'
import userCircle from '../../assets/images/user-circle.svg'
import { useFeedback } from '../../lib/hooks/useFeedback'

export const PricingPage: FC = () => {
  const dispatch = useAppDispatch()
  const { isSignedIn, user } = useAuth()
  const [wimbleHovered, setWimbleHovered] = useState(false)
  const [dinoHovered, setDinoHovered] = useState(false)
  const [message, setMessage] = useState('')
  const { handleSubmit } = useFeedback(message)

  const onSubmit = () => {
    handleSubmit(() => {
      setMessage('')
      dispatch(setSuccessModal(true))
    })
  }

  const onMessageChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setMessage(e.target.value)
  }

  const onWimbleMouseEnter = () => {
    setWimbleHovered(true)
  }

  const onWimbleMouseLeave = () => {
    setWimbleHovered(false)
  }

  const onDinoMouseEnter = () => {
    setDinoHovered(true)
  }

  const onDinoMouseLeave = () => {
    setDinoHovered(false)
  }

  const onPurchase = (priceType: PriceType) => async () => {
    if (!isSignedIn) {
      dispatch(setAuthModal(true))
      return
    }
    const response = await stripeSession(priceType)
    if (response?.status === 200 && response.data.url) {
      window.open(response.data.url, '_self')
    }
  }

  return (
    <div className='pricing-page'>
      <div className='pricing-page-content-box'>
        <div className='pricing-page-content-header'>
          <h1 className='pricing-page-title'>Magic priced fairly</h1>
          <p className='pricing-page-text'>We have no subscriptions, pay only for what you use</p>
        </div>
        <div className='pricing-page-cards-box'>
          <div className='pricing-page-card'>
            <div className='card-content'>
              <p className='card-title'>A pinch</p>
              <div className='card-credits'>
                <img src={creditsIcon} alt='' />
                <span className='card-amount'>20 credits</span>
              </div>
              <p className='card-price'>$4.99</p>
            </div>
            <Button
              className='card-button'
              text='purchase'
              onClick={onPurchase(PriceType.price_20)}
            />
          </div>
          <div className='pricing-page-card popular'>
            <div className='card-label'>
              <span>Popular option</span>
            </div>
            <div className='card-content'>
              <p className='card-title'>A sprinkle</p>
              <div className='card-credits'>
                <img src={creditsIcon} alt='' />
                <span className='card-amount'>50 credits</span>
              </div>
              <div className='card-price-box'>
                <p className='card-price'>$9.99</p>
                <p className='card-discount'>Save 20%</p>
              </div>
            </div>
            <Button
              className='card-button'
              text='purchase'
              onClick={onPurchase(PriceType.price_50)}
            />
          </div>
          <div className='pricing-page-card'>
            <div className='card-content'>
              <p className='card-title'>A handfull</p>
              <div className='card-credits'>
                <img src={creditsIcon} alt='' />
                <span className='card-amount'>100 credits</span>
              </div>
              <div className='card-price-box'>
                <p className='card-price'>$16.99</p>
                <p className='card-discount'>Save 32%</p>
              </div>
            </div>
            <Button
              className='card-button'
              text='purchase'
              onClick={onPurchase(PriceType.price_100)}
            />
          </div>
          <div className='pricing-page-card'>
            <div className='card-content'>
              <p className='card-title'>Need something beyond?</p>
              <p className='card-text'>
                Want to create something extraordinary or send your mail to more then 15 recipients?
              </p>
            </div>
            <motion.a
              initial={{
                backgroundImage:
                  'radial-gradient(91.12% 417.75% at 4.67% 0%, #C5F04D 1.32%, #FFCC00 50%, #FF60B7 100%)',
              }}
              whileHover={{
                backgroundImage:
                  'radial-gradient(40% 188.8% at -12.93% -18%, #C5F04D 1.32%, #FFCC00 20.73%, #FF60B7 100%)',
                transition: { duration: 0.3 },
              }}
              href='mailto:service@dinomail.io'
              className='card-link'
            >
              contact us
            </motion.a>
          </div>
        </div>
        <div className='pricing-page-content-footer'>
          <div className='pricing-page-info'>
            <img src={infoIcon} alt='' />
            <div>
              <p className='pricing-page-text'>
                Any purchase unlocks sending emails to up to 50 recipients at once.{' '}
                {isSignedIn
                  ? 'Please notice that each additional recipient will cost 1 credit extra.'
                  : ''}
              </p>
              {!isSignedIn && (
                <p className='pricing-page-text'>
                  Please notice that each additional recipient will cost 1 credit extra.
                </p>
              )}
            </div>
            <p className='pricing-page-subtext'>
              You will be warned about any extra charges during the creation process
            </p>
          </div>
          {isSignedIn && (
            <div className='pricing-page-feedback'>
              <div className='pricing-page-feedback-header'>
                <p className='pricing-page-feedback-title'>
                  <img src={chatIcon} alt='' role='presentation' />
                  <span>Have something to say about the MagicMail?</span>
                </p>
                <p className='pricing-page-feedback-text'>
                  Spotted a bug, came up with a cool feature you&apos;d like to see implemented, or
                  just want to share your experience? We’re looking forward to hearing from you!
                </p>
              </div>
              <div className='pricing-page-feedback-form'>
                <textarea
                  className='pricing-page-feedback-form-input'
                  placeholder='Write something...'
                  name='message'
                  rows={4}
                  value={message}
                  onChange={onMessageChange}
                />
              </div>
              <div className='pricing-page-feedback-footer'>
                <div className='pricing-page-feedback-footer-user-info'>
                  {user?.picture ? (
                    <img
                      className='pricing-page-feedback-footer-user-photo'
                      src={user.picture}
                      alt=''
                      referrerPolicy='no-referrer'
                    />
                  ) : (
                    <img src={userCircle} alt='' />
                  )}
                  <div>
                    <p className='pricing-page-feedback-footer-user-name'>{user?.name}</p>
                    <p className='pricing-page-feedback-footer-user-email'>{user?.email}</p>
                  </div>
                </div>
                <div className='pricing-page-feedback-footer-button-box'>
                  <Button
                    className='pricing-page-feedback-footer-button'
                    text='submit'
                    onClick={onSubmit}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='pricing-page-footer'>
        <p className='pricing-page-footer-text'>MagicMail © 2024 ∙ All rights reserved</p>
        <div className='pricing-page-footer-links'>
          <Link to='/privacy' className='pricing-page-footer-text'>
            <span>Privacy Policy</span>
          </Link>
          <Link to='/terms' className='pricing-page-footer-text'>
            <span>Terms and Services</span>
          </Link>
          <Link
            to='https://wimble.agency'
            target='_blank'
            className='pricing-page-footer-logo'
            onMouseEnter={onWimbleMouseEnter}
            onMouseLeave={onWimbleMouseLeave}
          >
            <span className='pricing-page-footer-text'>Conjured by</span>
            <WimbleLogo isHovered={wimbleHovered} />
          </Link>
          <Link
            to='https://dinomail.io/'
            target='_blank'
            className='pricing-page-footer-logo'
            onMouseEnter={onDinoMouseEnter}
            onMouseLeave={onDinoMouseLeave}
          >
            <span className='pricing-page-footer-text'>Enchanted by</span>
            <DinomailLogo isHovered={dinoHovered} />
          </Link>
        </div>
      </div>
    </div>
  )
}
