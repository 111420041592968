import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../store'
import { checkSignIn, fetchUser, signIn, signOut } from '../../store/slice/authSlice'
import { appleSignIn, googleSignIn } from '../api/auth'
import { setFormData } from '../services/asyncStorage'

export const useAuth = () => {
  const { isSignedIn, user } = useSelector((state: RootState) => state.auth)
  const formData = useSelector((state: RootState) => state.formData)
  const dispatch = useAppDispatch()

  const onGoogleSignIn = async () => {
    const response = await googleSignIn()
    if (response?.status === 200 && response.data.url) {
      setFormData(formData)
      window.open(response.data.url, '_self')
    }
  }

  const onGoogleCallback = async (code: string) => {
    dispatch(signIn({ type: 'google', code }))
  }

  const onAppleCallback = async (code: string, token: string) => {
    dispatch(signIn({ type: 'apple', code, token }))
  }

  const onAppleSignIn = async () => {
    const response = await appleSignIn()
    if (response?.status === 200 && response.data.url) {
      setFormData(formData)
      window.open(response.data.url, '_self')
    }
  }

  const onGetUser = () => {
    dispatch(fetchUser())
  }

  const onSignOut = () => {
    dispatch(signOut())
  }
  const onCheckSignIn = () => {
    dispatch(checkSignIn())
  }

  return {
    isSignedIn,
    user,
    onGoogleSignIn,
    onGoogleCallback,
    onAppleSignIn,
    onAppleCallback,
    onGetUser,
    onSignOut,
    onCheckSignIn,
  }
}
