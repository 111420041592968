import http from '../utils/http'
import { PriceType } from '../constants'

interface StripeSessionResponse {
  url: string
}

export async function stripeSession(priceType: PriceType) {
  try {
    return http.post<StripeSessionResponse>('/api/stripe/session', {
      price_type: priceType,
    })
  } catch (e) {
    console.log('googleSignIn error', e)
  }
}

export async function stripeWebhook() {
  try {
    return http.post('/api/stripe/webhook', {})
  } catch (e) {
    console.log('googleSignIn error', e)
  }
}
