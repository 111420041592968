import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { Flex } from '../blocks/flex/Flex'
import { Text } from '../blocks/text/Text'
import { Button } from '../button/Button'
import Modal from '../modal/Modal'
import './style.scss'
import { useSelector } from 'react-redux'
import { EmailDTO, sendEmail } from '../../store/slice/emailSlice'
import { RootState, useAppDispatch } from '../../store'
import { ToggleSwitch } from '../switchButton/SwitchButton'
import { ReactMultiEmail } from 'react-multi-email'
import 'react-multi-email/dist/style.css'
import { RemoveTag } from '../blocks/icon/Icon'
import { updateRecipient } from '../../store/slice/formDataSlice'
import { fetchUser } from '../../store/slice/authSlice'
import { setOopsModal, setSuccessFeedbackModal, setSuccessModal } from '../../store/slice/modalSlice'
import { useOutsideClick } from '../../lib/hooks/useOutsideClick'
import { AnimatePresence } from 'framer-motion'
import creditsIcon from '../../assets/images/credits-icon.svg'
import { getSuccessSendCount, setSuccessSendCount } from '../../lib/services/asyncStorage'

export const Popup: React.FC = () => {
  const dispatch = useAppDispatch()
  const { sendingStatus } = useSelector((state: any) => state.email)
  const { emailContent } = useSelector((state: RootState) => state.testEmail)
  const formData = useSelector((state: RootState) => state.formData)
  const [isMultipleRecipients, setIsMultipleRecipients] = useState(false)
  const [recipientEmails, setRecipientEmails] = useState<string[]>([])
  const [singleRecipientEmail, setSingleRecipientEmail] = useState<string[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const toggle = () => {
    setIsModalOpen(!isModalOpen)
  }

  const ref = useOutsideClick(toggle)

  useEffect(() => {
    dispatch(updateRecipient(isMultipleRecipients ? recipientEmails : singleRecipientEmail))
  }, [recipientEmails, singleRecipientEmail, isMultipleRecipients, dispatch])

  const handleSendEmail = async () => {
    if (!emailContent?.id || !formData.toEnd.length) {
      if (isMultipleRecipients) {
        setIsModalOpen(true)
      } else {
        inputRef.current?.focus()
      }
      return
    }
    const emailData: EmailDTO = {
      recipient_emails: formData.toEnd,
      email_id: emailContent.id,
    }
    try {
      await dispatch(sendEmail(emailData))
      await dispatch(fetchUser())
      const successSendCount = getSuccessSendCount() || 0
      if (successSendCount === 0) {
        dispatch(setSuccessFeedbackModal(true))
      } else {
        dispatch(setSuccessModal(true))
      }
      setSuccessSendCount((successSendCount + 1) % 3)
    } catch (error) {
      console.error('Failed to send email:', error)
      dispatch(setOopsModal(true))
    }
  }

  const handleToggle = () => {
    setIsMultipleRecipients(!isMultipleRecipients)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleChangeSingleRecipient: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSingleRecipientEmail([e.target.value])
  }

  const buttonRight = () => {
    return (
      <div className='button-right'>
        <img className='button-right-icon' src={creditsIcon} alt='' role='presentation' />
        <span className='button-right-text'>{isMultipleRecipients ? recipientEmails.length || 1 : 1}</span>
      </div>
    )
  }

  return (
    <Flex
      className='popup-wrapper'
      direction='row'
      align='center'
      justify='space-between'
      padding={'10px 20px'}
    >
      <Flex direction='row' align='center' justify='center' gap={20} className='text-wrapper'>
        <p className='to'>
          To<span>*</span>
        </p>
        {isMultipleRecipients ? (
          <button className='manage' onClick={openModal}>
            Manage recipients {recipientEmails.length ? `(${recipientEmails.length})` : ''}
          </button>
        ) : (
          <input
            ref={inputRef}
            name='recipient-emails'
            type='text'
            value={singleRecipientEmail}
            placeholder='Enter recipient email'
            className='recipient-input'
            onChange={handleChangeSingleRecipient}
          />
        )}
        <Flex align='center' gap={10}>
          <Text
            text='Multiple recipients'
            color='var(--dino-snow-white)'
            fontSize='14'
            opacity={0.5}
          />
          <ToggleSwitch isToggled={isMultipleRecipients} onToggle={handleToggle} />
        </Flex>
      </Flex>
      <Flex direction='row' align='center' justify='center' gap={10} className='btns-box'>
        <Button
          text={sendingStatus === 'loading' ? 'Sending...' : 'Send'}
          onClick={handleSendEmail}
          rightElement={buttonRight}
          disabled={!formData}
          className='popup-send-button'
        />
      </Flex>
      <AnimatePresence>
        {isModalOpen && (
          <Modal
            modalClassName='popup-recipients-modal'
            modalContentClassName='popup-recipients-modal-content'
          >
            <div className='popup-recipients-modal-content-box' ref={ref}>
              <div className='popup-recipients-text-box' ref={ref}>
                <h3 className='title-recipients'>Manage recipients</h3>
                <p className='entering-rules'>
                  You can add up to 50 recipients, divided by ↵ Enter. Please notice that each
                  sending will cost 1 credit extra.
                </p>
              </div>
              <div className='line' />
              <div className='email-box'>
                <span className='label'>Recipient’s email(s)</span>
                <ReactMultiEmail
                  inputClassName='popup-recipients-email-input'
                  emails={recipientEmails}
                  onChange={setRecipientEmails}
                  getLabel={(
                    email: string,
                    index: number,
                    removeEmail: (index: number) => void,
                  ) => {
                    return (
                      <div className='data-tag' key={index}>
                        {email}
                        <div className='data-tag-handle' onClick={() => removeEmail(index)}>
                          <RemoveTag />
                        </div>
                      </div>
                    )
                  }}
                />
              </div>
              <div className='line' />
              <span className='credit-calc'>
                {recipientEmails.length} recipient total — {recipientEmails.length} credit to send
              </span>
              <Button
                className='popup-recipients-modal-submit'
                text='Submit'
                disabled={!formData.toEnd.length}
                onClick={closeModal}
              />
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </Flex>
  )
}
