import { FC } from 'react'
import './style.scss'
import { Text } from '../blocks/text/Text'
import { Flex } from '../blocks/flex/Flex'
import { PlaceholderStars } from '../blocks/icon/Icon'

export const Placeholder: FC = () => {
  return (
    <Flex className='placeholder' direction='column' align='center' justify='center' gap={30}>
      <div className='placeholder-void'>
        <PlaceholderStars />
        <Flex direction='column' align='center' justify='center' gap={20}>
          <Text
            text='Nothing magical happened yet...'
            color='var(--dino-purple-box)'
            fontSize='24px'
          />
          <p className='place-desc'>
            Please complete the prompt on the left and hit ‘Generate an email’. We’ll display a
            preview of your <span className='place-magic'>MagicMail</span> in this area once
            it’s ready.
          </p>
        </Flex>
      </div>
    </Flex>
  )
}
